<template>
  <div>
    <v-overlay opacity="0.9" color="white" v-show="loading" absolute>
      <v-progress-circular indeterminate color="secondary" />
    </v-overlay>
    <pdf :src="pdfUrl" :page="1" @loaded="onLoaded" @error="onError" />
  </div>
</template>

<script>
import pdf from 'vue-pdf';

export default {
  components: {
    pdf,
  },
  props: {
    pdfUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    onLoaded() {
      this.loading = false;
    },
    onError() {
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
div {
  & :deep(span) {
    height: 180px;
    object-fit: contain;
    overflow: hidden;
  }
}
</style>